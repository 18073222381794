/**
 * 全局配置文件
 */
export default {
  clientId: 'saber', // 客户端id
  clientSecret: 'saber_secret', // 客户端密钥
  tokenHeader: 'Blade-Auth',
  statusWhiteList: [],
  switchMode: false
}
