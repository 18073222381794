<template>
  <div id="main">
    <van-nav-bar :title="routerName" @click-left="leftClick" @click-right="getScan">
      <template #left  v-if="$route.path !== '/login'">
        <van-icon v-if="showLeftIcon" :name="leftIcon" size="24"/>
        <van-icon v-if="!showLeftIcon" name="arrow-left" size="16" color="#fff">返回</van-icon>
      </template>
      <template #right v-if="showScan">
        <van-icon name="scan" size="24" color="#fff"/> 扫码/录入
      </template>
    </van-nav-bar>
    <div class="empty-div"></div>
    <van-sidebar v-model="activeRouter" v-show="showMenu" @click="showMenu = false" @change="onChange" class="sidebar-box">
      <van-sidebar-item v-for="(item, key) in routerList" :title="item.alias" :to="item.path" :key="key"></van-sidebar-item>
      <van-sidebar-item title="退出" @click="loginOut" :key="routerList.length"/>
    </van-sidebar>
    <van-overlay :show="showMenu" @click="showMenu = false" />
    <router-view />
    <van-dialog v-model:show="showDialog" title="扫码或者录入" show-cancel-button @confirm="confirm()">
      <van-cell-group inset>
        <van-field v-model="vmcName" label="机器编码" label-width="60" placeholder="请输入机器编码" />
        <p style="color: #ee0a24;opacity: 0.5; text-align: center;font-size: 12px">输入机器编码时，点击确定则不用扫码。不输入机器编码，点击确定则会打开摄像头扫码</p>
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
import wx from 'weixin-jsapi'
import { wxJSDK } from '../src/assets/api/wx.js'
import {vmcinfo} from "@/assets/api/site";
import { Toast } from 'vant'
export default {
  data() {
    return {
      vmcName: '',
      showDialog: false,
      leftIcon: require('./assets/imgs/conditions.png'),
      routerName: '首页',
      showMenu: false,
      showScan: false,
      showLeftIcon: true,
      activeRouter: 0
    }
  },
  computed: {
    routerList() {
      return this.$store.state.routers
    }
  },
  mounted() {
    console.log('mounted==>', this.activeRouter)
  },
  watch: {
    $route(to) { //监听路由是否变化
      this.routerName = to.meta.title
      if (to.path != '/login' && to.path != '/site_detail') {
        this.showScan = true
      } else {
        this.showScan = false
      }
      if (to.path.split('/').length > 2) {
        this.showLeftIcon = false
      } else {
        this.showLeftIcon = true
      }
    }
  },
  methods: {
    onChange() {
      this.showMenu = false
    },
    leftClick() {
      if (this.showLeftIcon) {
        this.showMenu = !this.showMenu
      } else {
        this.$router.go(-1)
      }
    },
    confirm() {
      if (this.vmcName) {
        this.vmcName = this.vmcName.toUpperCase()
        vmcinfo({vmcName: this.vmcName}).then(res => {
          if (res.data.code == 200) {
            if (res.data.data && res.data.data.vmcId) {
              this.$router.push({
                name: 'siteDetail',
                query: {
                  vmcId: res.data.data.vmcId
                }
              })
            }else {
              Toast('请输入正确的机器编码')
            }
          }
        })
      } else {
        const _this = this
        wxJSDK(location.href.split("#")[0]).then(res => {
          if (res.data.code == 200) {
            const data = res.data.data
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: "wx5403ef92b0104a25", // 必填，公众号的唯一标识
              timestamp: data.timestamp, // 必填，生成签名的时间戳
              nonceStr: data.noncestr, // 必填，生成签名的随机串
              signature: data.sing, // 必填，签名
              jsApiList: ["scanQRCode"] // 必填，需要使用的JS接口列表
            });
            wx.ready(function () {
              wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
                success: function (res) {
                  const url = res.resultStr // 当needResult 为 1 时，扫码返回的结果
                  const vmcId = url.split("=")[1]
                  // 送水员进入补货页面
                  _this.$router.push({
                    name: 'siteDetail',
                    query: {
                      vmcId: vmcId
                    }
                  })
                  // 维修员进入调试页面
                }
              })
            })
          }
        })
      }
    },
    getScan() {
      this.showDialog = true
    },
    loginOut() {
      localStorage.clear()
      this.activeRouter = 0
      console.log('loginOut==>', this.activeRouter)
      this.$router.push({name: 'login'})
    }
  }
}
</script>

<style scoped>
.sidebar-box {
  z-index: 1000;
  position: fixed;
  width: 120px;
  min-height: calc(100vh - 46px);
  background: #fff;
}
</style>