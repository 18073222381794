import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    // {
    //     path: '/',
    //     redirect: { name: 'home' },
    // },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登录'
        },
        component: () => import('../views/login/index.vue')
    },
    {
        path: '/home',
        name: 'home',
        meta: {
            title: '首页'
        },
        component: () => import('../views/home/index.vue')
    },
    {
        path: '/shop_count',
        name: 'shopCount',
        meta: {
            title: '商城统计数据'
        },
        component: () => import('../views/home/shopCount.vue')
    },
    {
        path: '/site_count',
        name: 'siteCount',
        meta: {
            title: '售卖机统计'
        },
        component: () => import('../views/home/siteCount.vue')
    },
    {
        path: '/site_infos',
        name: 'siteInfos',
        meta: {
            title: '售卖机统计详情'
        },
        component: () => import('../views/home/siteInfos.vue')
    },
    {
        path: '/site_count_copy',
        name: 'siteCountCopy',
        meta: {
            title: '售卖机统计'
        },
        component: () => import('../views/site_copy/siteCount.vue')
    },
    {
        path: '/site_infos_copy',
        name: 'siteInfosCopy',
        meta: {
            title: '售卖机统计详情'
        },
        component: () => import('../views/site_copy/siteInfos.vue')
    },
    {
        path: '/warning_count',
        name: 'warningCount',
        meta: {
            title: '报警统计'
        },
        component: () => import('../views/home/warningCount.vue')
    },
    {
        path: '/shop_order',
        name: 'shopOrder',
        meta: {
            title: '商城订单'
        },
        component: () => import('../views/shopOrder/index.vue')
    },
    {
        path: '/shop_refund',
        name: 'shopRefund',
        meta: {
            title: '商城退款'
        },
        component: () => import('../views/shopRefund/index.vue')
    },
    {
        path: '/vmc_refund',
        name: 'vmcRefund',
        meta: {
            title: '货架退款'
        },
        component: () => import('../views/vmcRefund/index.vue')
    },
    {
        path: '/order_detail',
        name: 'orderDetail',
        meta: {
            title: '订单详情'
        },
        component: () => import('../views/shopOrder/orderDetail.vue')
    },
    {
        path: '/net_point',
        name: 'net_point',
        meta: {
            title: '智能补货'
        },
        component: () => import('../views/netPoint/index.vue')
    },
    {
        path: '/net_point/detail',
        name: 'net_point_detail',
        meta: {
            title: '智能补货详情'
        },
        component: () => import('../views/netPoint/netPointDetail.vue')
    },
    {
        path: '/goods_select',
        name: 'goods_select',
        meta: {
            title: '货架库存情况'
        },
        component: () => import('../views/goodsSelect/index.vue')
    },
    {
        path: '/net_point/detail_infos',
        name: 'net_point_detail_infos',
        meta: {
            title: '机器详情'
        },
        component: () => import('../views/netPoint/detailInfos.vue')
    },
    {
        path: '/site',
        name: 'site',
        meta: {
            title: '货架'
        },
        component: () => import('../views/site/index.vue')
    },
    {
        path: '/site_detail',
        name: 'siteDetail',
        meta: {
            title: '货架详情'
        },
        component: () => import('../views/site/siteDetail.vue')
    },
    {
        path: '/ratio',
        name: 'ratio',
        meta: {
            title: '运营货架数/系数'
        },
        component: () => import('../views/ratio/index.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('access_token')
    // url 忽略大小写，将地址转小写判断
    if (to.path.toLowerCase() != '/login' && !token) {
        next({ path: '/login' })
    } else {
        if (to.path.toLowerCase() == '/login' && token) {
            next({ path: '/home' })
        } else {
            next()
        }
    }
})
router.afterEach(() => {
    window.scrollTo(0,0);
})
export default router
