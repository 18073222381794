import { createStore } from 'vuex'
import persistedState from 'vuex-persistedstate'
export default createStore({
    state: {
        loading: '',
        userInfo: {},
        token: '',
        refreshToken: '',
        routers: []
    },
    mutations: {
        // 全局loding
        showLoading(state) {
            state.loading = true
        },
        hideLoading(state) {
            state.loading = false
        },
        setUserInfo(state, infos) {
            state.userInfo = infos;
        },
        setToken(state, token) {
            state.token = token;
        },
        setRefreshToken(state, refreshToken) {
            state.refreshToken = refreshToken;
        },
        setRouters(state, routers) {
            state.routers = routers;
        }
    },
    actions: {

    },
    modules: {

    },
    plugins: [persistedState()]
})
