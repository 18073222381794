import { createApp } from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'
const app = createApp(App)
import { Dialog, Calendar, Area, Loading, Tag, Icon, NavBar, Tab, Tabs, Form, Field, Circle,Checkbox, CellGroup,DropdownMenu, DropdownItem,TreeSelect,
    Toast, Picker, Button, Card, Tabbar, Swipe, SwipeItem,Overlay,CheckboxGroup,
    PullRefresh, List, Cell, Search, TabbarItem, Image as VanImage, CouponCell, CouponList, ActionSheet, RadioGroup, Radio, Popup, Stepper, Sidebar, SidebarItem } from 'vant';
import 'vant/lib/index.css';
import './assets/css/common.less';
app.use(Checkbox)
app.use(TreeSelect)
app.use(DropdownMenu).use(Calendar)
app.use(DropdownItem)
app.use(CheckboxGroup)
app.use(NavBar)
app.use(Tab)
app.use(Tabs)
app.use(Tabbar)
app.use(TabbarItem)
app.use(Swipe)
app.use(SwipeItem)
app.use(Form)
app.use(Field)
app.use(Picker)
app.use(Button)
app.use(Icon)
app.use(VanImage)
app.use(Card)
app.use(PullRefresh)
app.use(Tag)
app.use(Area)
app.use(Dialog)
app.use(Loading)
app.use(List)
app.use(Cell).use(CellGroup)
app.use(Search)
app.use(ActionSheet)
app.use(RadioGroup)
app.use(Radio)
app.use(Circle).use(Overlay)
app.use(Stepper).use(Sidebar).use(SidebarItem)
app.use(Toast).use(Popup).use(CouponCell).use(CouponList)
app.use(store).use(router).mount('#app')
