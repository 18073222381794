import request from './request.js';

export const channelInfoList = (params) => {
  return request({
    url: '/api/blade-admin/channelinfo/list',
    method: 'get',
    params
  })
}

export const openLock = (params) => {
  return request({
    url: '/api/blade-admin/excuteinfo/openLock',
    method: 'get',
    params
  })
}

export const goodsList = (params) => {
  return request({
    url: '/api/blade-admin/prod/page',
    method: 'get',
    params
  })
}


export const submitChannel = (data) => {
  return request({
    url: '/api/blade-admin/channelinfo/submit',
    method: 'post',
    data
  })
}

export const submitChannelList = (data) => {
  return request({
    url: '/api/blade-admin/channelinfo/submitList',
    method: 'post',
    data
  })
}

export const vmcinfo = (params) => {
  return request({
    url: '/api/blade-admin/vmcinfo/detail',
    method: 'get',
    params
  })
}


// 机器换货接口
export const exchangeRecord = (data) => {
  return request({
    url: '/api/blade-admin/exchangerecord/submit',
    method: 'post',
    data
  })
}