//表单序列化
export const serialize = data => {
  let list = [];
  Object.keys(data).forEach(ele => {
    list.push(`${ele}=${data[ele]}`)
  })
  return list.join('&');
};


export const tenantList = () => {
  return [
    { tenantId: '000000', value: '000000', label: '长沙市' },
    { tenantId: '654151', value: '654151', label: '株洲市' },
    { tenantId: '156110000', value: '156110000', label: '北京市' },
    { tenantId: '156120000', value: '156120000', label: '天津市' },
    { tenantId: '156130100', value: '156130100', label: '石家庄市' },
    { tenantId: '156130200', value: '156130200', label: '唐山市' },
    { tenantId: '156130300', value: '156130300', label: '秦皇岛市' },
    { tenantId: '156130400', value: '156130400', label: '邯郸市' },
    { tenantId: '156130500', value: '156130500', label: '邢台市' },
    { tenantId: '156130600', value: '156130600', label: '保定市' },
    { tenantId: '156130700', value: '156130700', label: '张家口市' },
    { tenantId: '156130800', value: '156130800', label: '承德市' },
    { tenantId: '156130900', value: '156130900', label: '沧州市' },
    { tenantId: '156131000', value: '156131000', label: '廊坊市' },
    { tenantId: '156131100', value: '156131100', label: '衡水市' },
    { tenantId: '156140100', value: '156140100', label: '太原市' },
    { tenantId: '156140200', value: '156140200', label: '大同市' },
    { tenantId: '156140300', value: '156140300', label: '阳泉市' },
    { tenantId: '156140400', value: '156140400', label: '长治市' },
    { tenantId: '156140500', value: '156140500', label: '晋城市' },
    { tenantId: '156140600', value: '156140600', label: '朔州市' },
    { tenantId: '156140700', value: '156140700', label: '晋中市' },
    { tenantId: '156140800', value: '156140800', label: '运城市' },
    { tenantId: '156140900', value: '156140900', label: '忻州市' },
    { tenantId: '156141000', value: '156141000', label: '临汾市' },
    { tenantId: '156141100', value: '156141100', label: '吕梁市' },
    { tenantId: '156150100', value: '156150100', label: '呼和浩特市' },
    { tenantId: '156150200', value: '156150200', label: '包头市' },
    { tenantId: '156150300', value: '156150300', label: '乌海市' },
    { tenantId: '156150400', value: '156150400', label: '赤峰市' },
    { tenantId: '156150500', value: '156150500', label: '通辽市' },
    { tenantId: '156150600', value: '156150600', label: '鄂尔多斯市' },
    { tenantId: '156150700', value: '156150700', label: '呼伦贝尔市' },
    { tenantId: '156150800', value: '156150800', label: '巴彦淖尔市' },
    { tenantId: '156150900', value: '156150900', label: '乌兰察布市' },
    { tenantId: '156152200', value: '156152200', label: '兴安盟' },
    { tenantId: '156152500', value: '156152500', label: '锡林郭勒盟' },
    { tenantId: '156152900', value: '156152900', label: '阿拉善盟' },
    { tenantId: '156210100', value: '156210100', label: '沈阳市' },
    { tenantId: '156210200', value: '156210200', label: '大连市' },
    { tenantId: '156210300', value: '156210300', label: '鞍山市' },
    { tenantId: '156210400', value: '156210400', label: '抚顺市' },
    { tenantId: '156210500', value: '156210500', label: '本溪市' },
    { tenantId: '156210600', value: '156210600', label: '丹东市' },
    { tenantId: '156210700', value: '156210700', label: '锦州市' },
    { tenantId: '156210800', value: '156210800', label: '营口市' },
    { tenantId: '156210900', value: '156210900', label: '阜新市' },
    { tenantId: '156211000', value: '156211000', label: '辽阳市' },
    { tenantId: '156211100', value: '156211100', label: '盘锦市' },
    { tenantId: '156211200', value: '156211200', label: '铁岭市' },
    { tenantId: '156211300', value: '156211300', label: '朝阳市' },
    { tenantId: '156211400', value: '156211400', label: '葫芦岛市' },
    { tenantId: '156220100', value: '156220100', label: '长春市' },
    { tenantId: '156220200', value: '156220200', label: '吉林市' },
    { tenantId: '156220300', value: '156220300', label: '四平市' },
    { tenantId: '156220400', value: '156220400', label: '辽源市' },
    { tenantId: '156220500', value: '156220500', label: '通化市' },
    { tenantId: '156220600', value: '156220600', label: '白山市' },
    { tenantId: '156220700', value: '156220700', label: '松原市' },
    { tenantId: '156220800', value: '156220800', label: '白城市' },
    { tenantId: '156222400', value: '156222400', label: '延边朝鲜族自治州' },
    { tenantId: '156230100', value: '156230100', label: '哈尔滨市' },
    { tenantId: '156230200', value: '156230200', label: '齐齐哈尔市' },
    { tenantId: '156230300', value: '156230300', label: '鸡西市' },
    { tenantId: '156230400', value: '156230400', label: '鹤岗市' },
    { tenantId: '156230500', value: '156230500', label: '双鸭山市' },
    { tenantId: '156230600', value: '156230600', label: '大庆市' },
    { tenantId: '156230700', value: '156230700', label: '伊春市' },
    { tenantId: '156230800', value: '156230800', label: '佳木斯市' },
    { tenantId: '156230900', value: '156230900', label: '七台河市' },
    { tenantId: '156231000', value: '156231000', label: '牡丹江市' },
    { tenantId: '156231100', value: '156231100', label: '黑河市' },
    { tenantId: '156231200', value: '156231200', label: '绥化市' },
    { tenantId: '156232700', value: '156232700', label: '大兴安岭地区' },
    { tenantId: '156310000', value: '156310000', label: '上海市' },
    { tenantId: '156320100', value: '156320100', label: '南京市' },
    { tenantId: '156320200', value: '156320200', label: '无锡市' },
    { tenantId: '156320300', value: '156320300', label: '徐州市' },
    { tenantId: '156320400', value: '156320400', label: '常州市' },
    { tenantId: '156320500', value: '156320500', label: '苏州市' },
    { tenantId: '156320600', value: '156320600', label: '南通市' },
    { tenantId: '156320700', value: '156320700', label: '连云港市' },
    { tenantId: '156320800', value: '156320800', label: '淮安市' },
    { tenantId: '156320900', value: '156320900', label: '盐城市' },
    { tenantId: '156321000', value: '156321000', label: '扬州市' },
    { tenantId: '156321100', value: '156321100', label: '镇江市' },
    { tenantId: '156321200', value: '156321200', label: '泰州市' },
    { tenantId: '156321300', value: '156321300', label: '宿迁市' },
    { tenantId: '156330100', value: '156330100', label: '杭州市' },
    { tenantId: '156330200', value: '156330200', label: '宁波市' },
    { tenantId: '156330300', value: '156330300', label: '温州市' },
    { tenantId: '156330400', value: '156330400', label: '嘉兴市' },
    { tenantId: '156330500', value: '156330500', label: '湖州市' },
    { tenantId: '156330600', value: '156330600', label: '绍兴市' },
    { tenantId: '156330700', value: '156330700', label: '金华市' },
    { tenantId: '156330800', value: '156330800', label: '衢州市' },
    { tenantId: '156330900', value: '156330900', label: '舟山市' },
    { tenantId: '156331000', value: '156331000', label: '台州市' },
    { tenantId: '156331100', value: '156331100', label: '丽水市' },
    { tenantId: '156340100', value: '156340100', label: '合肥市' },
    { tenantId: '156340200', value: '156340200', label: '芜湖市' },
    { tenantId: '156340300', value: '156340300', label: '蚌埠市' },
    { tenantId: '156340400', value: '156340400', label: '淮南市' },
    { tenantId: '156340500', value: '156340500', label: '马鞍山市' },
    { tenantId: '156340600', value: '156340600', label: '淮北市' },
    { tenantId: '156340700', value: '156340700', label: '铜陵市' },
    { tenantId: '156340800', value: '156340800', label: '安庆市' },
    { tenantId: '156341000', value: '156341000', label: '黄山市' },
    { tenantId: '156341100', value: '156341100', label: '滁州市' },
    { tenantId: '156341200', value: '156341200', label: '阜阳市' },
    { tenantId: '156341300', value: '156341300', label: '宿州市' },
    { tenantId: '156341500', value: '156341500', label: '六安市' },
    { tenantId: '156341600', value: '156341600', label: '亳州市' },
    { tenantId: '156341700', value: '156341700', label: '池州市' },
    { tenantId: '156341800', value: '156341800', label: '宣城市' },
    { tenantId: '156350100', value: '156350100', label: '福州市' },
    { tenantId: '156350200', value: '156350200', label: '厦门市' },
    { tenantId: '156350300', value: '156350300', label: '莆田市' },
    { tenantId: '156350400', value: '156350400', label: '三明市' },
    { tenantId: '156350500', value: '156350500', label: '泉州市' },
    { tenantId: '156350600', value: '156350600', label: '漳州市' },
    { tenantId: '156350700', value: '156350700', label: '南平市' },
    { tenantId: '156350800', value: '156350800', label: '龙岩市' },
    { tenantId: '156350900', value: '156350900', label: '宁德市' },
    { tenantId: '156360100', value: '156360100', label: '南昌市' },
    { tenantId: '156360200', value: '156360200', label: '景德镇市' },
    { tenantId: '156360300', value: '156360300', label: '萍乡市' },
    { tenantId: '156360400', value: '156360400', label: '九江市' },
    { tenantId: '156360500', value: '156360500', label: '新余市' },
    { tenantId: '156360600', value: '156360600', label: '鹰潭市' },
    { tenantId: '156360700', value: '156360700', label: '赣州市' },
    { tenantId: '156360800', value: '156360800', label: '吉安市' },
    { tenantId: '156360900', value: '156360900', label: '宜春市' },
    { tenantId: '156361000', value: '156361000', label: '抚州市' },
    { tenantId: '156361100', value: '156361100', label: '上饶市' },
    { tenantId: '156370100', value: '156370100', label: '济南市' },
    { tenantId: '156370200', value: '156370200', label: '青岛市' },
    { tenantId: '156370300', value: '156370300', label: '淄博市' },
    { tenantId: '156370400', value: '156370400', label: '枣庄市' },
    { tenantId: '156370500', value: '156370500', label: '东营市' },
    { tenantId: '156370600', value: '156370600', label: '烟台市' },
    { tenantId: '156370700', value: '156370700', label: '潍坊市' },
    { tenantId: '156370800', value: '156370800', label: '济宁市' },
    { tenantId: '156370900', value: '156370900', label: '泰安市' },
    { tenantId: '156371000', value: '156371000', label: '威海市' },
    { tenantId: '156371100', value: '156371100', label: '日照市' },
    { tenantId: '156371300', value: '156371300', label: '临沂市' },
    { tenantId: '156371400', value: '156371400', label: '德州市' },
    { tenantId: '156371500', value: '156371500', label: '聊城市' },
    { tenantId: '156371600', value: '156371600', label: '滨州市' },
    { tenantId: '156371700', value: '156371700', label: '菏泽市' },
    { tenantId: '156410100', value: '156410100', label: '郑州市' },
    { tenantId: '156410200', value: '156410200', label: '开封市' },
    { tenantId: '156410300', value: '156410300', label: '洛阳市' },
    { tenantId: '156410400', value: '156410400', label: '平顶山市' },
    { tenantId: '156410500', value: '156410500', label: '安阳市' },
    { tenantId: '156410600', value: '156410600', label: '鹤壁市' },
    { tenantId: '156410700', value: '156410700', label: '新乡市' },
    { tenantId: '156410800', value: '156410800', label: '焦作市' },
    { tenantId: '156410900', value: '156410900', label: '濮阳市' },
    { tenantId: '156411000', value: '156411000', label: '许昌市' },
    { tenantId: '156411100', value: '156411100', label: '漯河市' },
    { tenantId: '156411200', value: '156411200', label: '三门峡市' },
    { tenantId: '156411300', value: '156411300', label: '南阳市' },
    { tenantId: '156411400', value: '156411400', label: '商丘市' },
    { tenantId: '156411500', value: '156411500', label: '信阳市' },
    { tenantId: '156411600', value: '156411600', label: '周口市' },
    { tenantId: '156411700', value: '156411700', label: '驻马店市' },
    { tenantId: '156410000', value: '156410000', label: '河南省直辖' },
    { tenantId: '156420100', value: '156420100', label: '武汉市' },
    { tenantId: '156420200', value: '156420200', label: '黄石市' },
    { tenantId: '156420300', value: '156420300', label: '十堰市' },
    { tenantId: '156420500', value: '156420500', label: '宜昌市' },
    { tenantId: '156420600', value: '156420600', label: '襄阳市' },
    { tenantId: '156420700', value: '156420700', label: '鄂州市' },
    { tenantId: '156420800', value: '156420800', label: '荆门市' },
    { tenantId: '156420900', value: '156420900', label: '孝感市' },
    { tenantId: '156421000', value: '156421000', label: '荆州市' },
    { tenantId: '156421100', value: '156421100', label: '黄冈市' },
    { tenantId: '156421200', value: '156421200', label: '咸宁市' },
    { tenantId: '156421300', value: '156421300', label: '随州市' },
    { tenantId: '156422800', value: '156422800', label: '恩施土家族苗族自治州' },
    { tenantId: '156420000', value: '156420000', label: '湖北省直辖' },
    // { tenantId: '156430100', value: '156430100', label: '长沙市' },
    // { tenantId: '156430200', value: '156430200', label: '株洲市' },
    { tenantId: '156430300', value: '156430300', label: '湘潭市' },
    { tenantId: '156430400', value: '156430400', label: '衡阳市' },
    { tenantId: '156430500', value: '156430500', label: '邵阳市' },
    { tenantId: '156430600', value: '156430600', label: '岳阳市' },
    { tenantId: '156430700', value: '156430700', label: '常德市' },
    { tenantId: '156430800', value: '156430800', label: '张家界市' },
    { tenantId: '156430900', value: '156430900', label: '益阳市' },
    { tenantId: '156431000', value: '156431000', label: '郴州市' },
    { tenantId: '156431100', value: '156431100', label: '永州市' },
    { tenantId: '156431200', value: '156431200', label: '怀化市' },
    { tenantId: '156431300', value: '156431300', label: '娄底市' },
    { tenantId: '156433100', value: '156433100', label: '湘西土家族苗族自治州' },
    { tenantId: '156440100', value: '156440100', label: '广州市' },
    { tenantId: '156440200', value: '156440200', label: '韶关市' },
    { tenantId: '156440300', value: '156440300', label: '深圳市' },
    { tenantId: '156440400', value: '156440400', label: '珠海市' },
    { tenantId: '156440500', value: '156440500', label: '汕头市' },
    { tenantId: '156440600', value: '156440600', label: '佛山市' },
    { tenantId: '156440700', value: '156440700', label: '江门市' },
    { tenantId: '156440800', value: '156440800', label: '湛江市' },
    { tenantId: '156440900', value: '156440900', label: '茂名市' },
    { tenantId: '156441200', value: '156441200', label: '肇庆市' },
    { tenantId: '156441300', value: '156441300', label: '惠州市' },
    { tenantId: '156441400', value: '156441400', label: '梅州市' },
    { tenantId: '156441500', value: '156441500', label: '汕尾市' },
    { tenantId: '156441600', value: '156441600', label: '河源市' },
    { tenantId: '156441700', value: '156441700', label: '阳江市' },
    { tenantId: '156441800', value: '156441800', label: '清远市' },
    { tenantId: '156441900', value: '156441900', label: '东莞市' },
    { tenantId: '156442000', value: '156442000', label: '中山市' },
    { tenantId: '156445100', value: '156445100', label: '潮州市' },
    { tenantId: '156445200', value: '156445200', label: '揭阳市' },
    { tenantId: '156445300', value: '156445300', label: '云浮市' },
    { tenantId: '156450100', value: '156450100', label: '南宁市' },
    { tenantId: '156450200', value: '156450200', label: '柳州市' },
    { tenantId: '156450300', value: '156450300', label: '桂林市' },
    { tenantId: '156450400', value: '156450400', label: '梧州市' },
    { tenantId: '156450500', value: '156450500', label: '北海市' },
    { tenantId: '156450600', value: '156450600', label: '防城港市' },
    { tenantId: '156450700', value: '156450700', label: '钦州市' },
    { tenantId: '156450800', value: '156450800', label: '贵港市' },
    { tenantId: '156450900', value: '156450900', label: '玉林市' },
    { tenantId: '156451000', value: '156451000', label: '百色市' },
    { tenantId: '156451100', value: '156451100', label: '贺州市' },
    { tenantId: '156451200', value: '156451200', label: '河池市' },
    { tenantId: '156451300', value: '156451300', label: '来宾市' },
    { tenantId: '156451400', value: '156451400', label: '崇左市' },
    { tenantId: '156460100', value: '156460100', label: '海口市' },
    { tenantId: '156460200', value: '156460200', label: '三亚市' },
    { tenantId: '156460300', value: '156460300', label: '三沙市' },
    { tenantId: '156460400', value: '156460400', label: '儋州市' },
    { tenantId: '156460000', value: '156460000', label: '海南省直辖' },
    { tenantId: '156500000', value: '156500000', label: '重庆市' },
    { tenantId: '156510100', value: '156510100', label: '成都市' },
    { tenantId: '156510300', value: '156510300', label: '自贡市' },
    { tenantId: '156510400', value: '156510400', label: '攀枝花市' },
    { tenantId: '156510500', value: '156510500', label: '泸州市' },
    { tenantId: '156510600', value: '156510600', label: '德阳市' },
    { tenantId: '156510700', value: '156510700', label: '绵阳市' },
    { tenantId: '156510800', value: '156510800', label: '广元市' },
    { tenantId: '156510900', value: '156510900', label: '遂宁市' },
    { tenantId: '156511000', value: '156511000', label: '内江市' },
    { tenantId: '156511100', value: '156511100', label: '乐山市' },
    { tenantId: '156511300', value: '156511300', label: '南充市' },
    { tenantId: '156511400', value: '156511400', label: '眉山市' },
    { tenantId: '156511500', value: '156511500', label: '宜宾市' },
    { tenantId: '156511600', value: '156511600', label: '广安市' },
    { tenantId: '156511700', value: '156511700', label: '达州市' },
    { tenantId: '156511800', value: '156511800', label: '雅安市' },
    { tenantId: '156511900', value: '156511900', label: '巴中市' },
    { tenantId: '156512000', value: '156512000', label: '资阳市' },
    { tenantId: '156513200', value: '156513200', label: '阿坝藏族羌族自治州' },
    { tenantId: '156513300', value: '156513300', label: '甘孜藏族自治州' },
    { tenantId: '156513400', value: '156513400', label: '凉山彝族自治州' },
    { tenantId: '156520100', value: '156520100', label: '贵阳市' },
    { tenantId: '156520200', value: '156520200', label: '六盘水市' },
    { tenantId: '156520300', value: '156520300', label: '遵义市' },
    { tenantId: '156520400', value: '156520400', label: '安顺市' },
    { tenantId: '156520500', value: '156520500', label: '毕节市' },
    { tenantId: '156520600', value: '156520600', label: '铜仁市' },
    { tenantId: '156522300', value: '156522300', label: '黔西南布依族苗族自治州' },
    { tenantId: '156522600', value: '156522600', label: '黔东南苗族侗族自治州' },
    { tenantId: '156522700', value: '156522700', label: '黔南布依族苗族自治州' },
    { tenantId: '156530100', value: '156530100', label: '昆明市' },
    { tenantId: '156530300', value: '156530300', label: '曲靖市' },
    { tenantId: '156530400', value: '156530400', label: '玉溪市' },
    { tenantId: '156530500', value: '156530500', label: '保山市' },
    { tenantId: '156530600', value: '156530600', label: '昭通市' },
    { tenantId: '156530700', value: '156530700', label: '丽江市' },
    { tenantId: '156530800', value: '156530800', label: '普洱市' },
    { tenantId: '156530900', value: '156530900', label: '临沧市' },
    { tenantId: '156532300', value: '156532300', label: '楚雄彝族自治州' },
    { tenantId: '156532500', value: '156532500', label: '红河哈尼族彝族自治州' },
    { tenantId: '156532600', value: '156532600', label: '文山壮族苗族自治州' },
    { tenantId: '156532800', value: '156532800', label: '西双版纳傣族自治州' },
    { tenantId: '156532900', value: '156532900', label: '大理白族自治州' },
    { tenantId: '156533100', value: '156533100', label: '德宏傣族景颇族自治州' },
    { tenantId: '156533300', value: '156533300', label: '怒江傈僳族自治州' },
    { tenantId: '156533400', value: '156533400', label: '迪庆藏族自治州' },
    { tenantId: '156540100', value: '156540100', label: '拉萨市' },
    { tenantId: '156540200', value: '156540200', label: '日喀则市' },
    { tenantId: '156540300', value: '156540300', label: '昌都市' },
    { tenantId: '156540400', value: '156540400', label: '林芝市' },
    { tenantId: '156540500', value: '156540500', label: '山南市' },
    { tenantId: '156540600', value: '156540600', label: '那曲市' },
    { tenantId: '156542500', value: '156542500', label: '阿里地区' },
    { tenantId: '156610100', value: '156610100', label: '西安市' },
    { tenantId: '156610200', value: '156610200', label: '铜川市' },
    { tenantId: '156610300', value: '156610300', label: '宝鸡市' },
    { tenantId: '156610400', value: '156610400', label: '咸阳市' },
    { tenantId: '156610500', value: '156610500', label: '渭南市' },
    { tenantId: '156610600', value: '156610600', label: '延安市' },
    { tenantId: '156610700', value: '156610700', label: '汉中市' },
    { tenantId: '156610800', value: '156610800', label: '榆林市' },
    { tenantId: '156610900', value: '156610900', label: '安康市' },
    { tenantId: '156611000', value: '156611000', label: '商洛市' },
    { tenantId: '156620100', value: '156620100', label: '兰州市' },
    { tenantId: '156620200', value: '156620200', label: '嘉峪关市' },
    { tenantId: '156620300', value: '156620300', label: '金昌市' },
    { tenantId: '156620400', value: '156620400', label: '白银市' },
    { tenantId: '156620500', value: '156620500', label: '天水市' },
    { tenantId: '156620600', value: '156620600', label: '武威市' },
    { tenantId: '156620700', value: '156620700', label: '张掖市' },
    { tenantId: '156620800', value: '156620800', label: '平凉市' },
    { tenantId: '156620900', value: '156620900', label: '酒泉市' },
    { tenantId: '156621000', value: '156621000', label: '庆阳市' },
    { tenantId: '156621100', value: '156621100', label: '定西市' },
    { tenantId: '156621200', value: '156621200', label: '陇南市' },
    { tenantId: '156622900', value: '156622900', label: '临夏回族自治州' },
    { tenantId: '156623000', value: '156623000', label: '甘南藏族自治州' },
    { tenantId: '156620000', value: '156620000', label: '甘肃省直辖' },
    { tenantId: '156630100', value: '156630100', label: '西宁市' },
    { tenantId: '156630200', value: '156630200', label: '海东市' },
    { tenantId: '156632200', value: '156632200', label: '海北藏族自治州' },
    { tenantId: '156632300', value: '156632300', label: '黄南藏族自治州' },
    { tenantId: '156632500', value: '156632500', label: '海南藏族自治州' },
    { tenantId: '156632600', value: '156632600', label: '果洛藏族自治州' },
    { tenantId: '156632700', value: '156632700', label: '玉树藏族自治州' },
    { tenantId: '156632800', value: '156632800', label: '海西蒙古族藏族自治州' },
    { tenantId: '156640100', value: '156640100', label: '银川市' },
    { tenantId: '156640200', value: '156640200', label: '石嘴山市' },
    { tenantId: '156640300', value: '156640300', label: '吴忠市' },
    { tenantId: '156640400', value: '156640400', label: '固原市' },
    { tenantId: '156640500', value: '156640500', label: '中卫市' },
    { tenantId: '156650100', value: '156650100', label: '乌鲁木齐市' },
    { tenantId: '156650200', value: '156650200', label: '克拉玛依市' },
    { tenantId: '156650400', value: '156650400', label: '吐鲁番市' },
    { tenantId: '156650500', value: '156650500', label: '哈密市' },
    { tenantId: '156652300', value: '156652300', label: '昌吉回族自治州' },
    { tenantId: '156652700', value: '156652700', label: '博尔塔拉蒙古自治州' },
    { tenantId: '156652800', value: '156652800', label: '巴音郭楞蒙古自治州' },
    { tenantId: '156652900', value: '156652900', label: '阿克苏地区' },
    { tenantId: '156653000', value: '156653000', label: '克孜勒苏柯尔克孜自治州' },
    { tenantId: '156653100', value: '156653100', label: '喀什地区' },
    { tenantId: '156653200', value: '156653200', label: '和田地区' },
    { tenantId: '156654000', value: '156654000', label: '伊犁哈萨克自治州' },
    { tenantId: '156654200', value: '156654200', label: '塔城地区' },
    { tenantId: '156654300', value: '156654300', label: '阿勒泰地区' },
    { tenantId: '156650000', value: '156650000', label: '新疆维吾尔自治区直辖' },
    { tenantId: '156710000', value: '156710000', label: '台湾省' },
    { tenantId: '156810000', value: '156810000', label: '香港特别行政区' },
    { tenantId: '156820000', value: '156820000', label: '澳门特别行政区' },
  ]
}